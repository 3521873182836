import { useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import validate from '../../../lib/validate';
import http from '../../../lib/http';

function NewVirtualEntry({ leagueId, onCreate, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const displayNameInputEl = useRef(null);
  const pointInputEl = useRef(null);
  const [invalidDisplayName, setInvalidDisplayName] = useState(false);
  const [invalidPoint, setInvalidPoint] = useState(false);

  async function handleCreate() {
    let allValid = true;

    const displayName = displayNameInputEl.current.value;
    const isValidDisplayName = validate.nonEmptyString(displayName);
    setInvalidDisplayName(!isValidDisplayName);
    allValid = allValid && isValidDisplayName;

    const point = pointInputEl.current.value;
    const isValidPoint = validate.integerGreaterThanOrEqualToZero(point);
    setInvalidPoint(!isValidPoint);
    allValid = allValid && isValidPoint;

    if (!allValid) {
      return;
    }

    try {
      await http.post(`/api/leagues/${leagueId}/entries`, {
        displayName,
        point,
      });
      enqueueSnackbar(`가상 참가자(${displayName})가 등록되었습니다.`, {
        variant: 'success',
      });
      onCreate();
    } catch (err) {
      enqueueSnackbar(`가상 참가자(${displayName}) 추가에 실패하였습니다.`, {
        variant: 'error',
      });
      console.error(err);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    handleCreate();
  }

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>새 가상 참가자</DialogTitle>
        <DialogContent>
          <DialogContentText>
            새 가상 참가자의 이름을 입력해주세요.
            <br />
            (가상 참가자는 가입되어 있는 회원일 필요가 없습니다.)
          </DialogContentText>
          <TextField
            inputRef={displayNameInputEl}
            autoFocus
            margin="dense"
            label="이름"
            fullWidth
            error={invalidDisplayName}
            helperText={invalidDisplayName && '이름을 입력하세요.'}
          />
          <TextField
            inputRef={pointInputEl}
            defaultValue={1500}
            margin="dense"
            label="시작 점수"
            fullWidth
            error={invalidPoint}
            helperText={invalidPoint && '0 이상의 정수를 입력해 주세요.'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button type="submit" color="primary">
            추가
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NewVirtualEntry;
