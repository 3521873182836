import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useRefreshLeagues } from '../../recoil/atoms';
import http from '../../lib/http';

function EditLeague({ league, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const refreshLeagues = useRefreshLeagues();
  const inputEl = useRef(null);

  async function handleUpdate() {
    // TODO: validation
    const displayName = inputEl.current.value;
    try {
      await http.put(`/api/leagues/${league.id}`, { displayName });
      enqueueSnackbar(`리그 이름이 변경되었습니다.`, {
        variant: 'success',
      });
      refreshLeagues();
      onClose();
    } catch (err) {
      enqueueSnackbar(`리그 이름 변경에 실패하였습니다.`, {
        variant: 'error',
      });
      console.error(err);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    handleUpdate();
  }

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>리그 이름 변경</DialogTitle>
        <DialogContent>
          <DialogContentText>변경할 이름을 입력해주세요.</DialogContentText>
          <TextField
            inputRef={(el) => {
              if (el) {
                el.select();
                inputEl.current = el;
              }
            }}
            autoFocus
            margin="dense"
            label="이름"
            defaultValue={league.displayName}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleUpdate} color="primary">
            변경
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditLeague;
