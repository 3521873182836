import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { loggedInState } from '../../recoil/atoms';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Box from '@material-ui/core/Box';
import { GoogleLogin } from '@react-oauth/google';
import { makeStyles } from '@material-ui/core/styles';
import http from '../../lib/http';
import { useRefreshAll } from '../../recoil/atoms';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    textTransform: 'none',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}));

function Login() {
  const classes = useStyles();
  const history = useHistory();
  const setLoggedIn = useSetRecoilState(loggedInState);
  const [errMessage, setErrMessage] = useState(null);
  const refreshAll = useRefreshAll();

  function handleLoginFailure(err) {
    if (err instanceof http.Error) {
      if (err.body.err === 'duplicate-email') {
        setErrMessage(
          '이 계정의 이메일 주소는 이미 다른 사용자가 사용하고 있습니다.',
        );
      } else {
        setErrMessage(err.body.err);
      }
    } else {
      console.error(err);
      setErrMessage('로그인에 실패하였습니다.');
    }
  }

  async function handleGoogleSuccess(data) {
    try {
      await http.post('/api/auth/google', {
        credential: data.credential,
      });
      setLoggedIn(true);
      refreshAll();
      history.push('/leagues');
    } catch (err) {
      handleLoginFailure(err);
    }
  }

  function handleGoogleFailure(err) {
    console.error(err);
    setErrMessage('구글 계정으로 로그인하는데 실패하였습니다.');
  }

  return (
    <Container className={classes.root}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      {errMessage ? (
        <Alert severity="error" style={{ width: '100%' }}>
          {errMessage}
        </Alert>
      ) : null}
      <Box display="flex" justifyContent="center" style={{ marginTop: '30px' }}>
        <GoogleLogin
          theme="filled_blue"
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
        />
      </Box>
    </Container>
  );
}

export default Login;
