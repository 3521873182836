import { useState } from 'react';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Paper from '@material-ui/core/Paper';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { recentGamesQuery, entriesQuery } from '../../../recoil/atoms';

function hslToHex(h, s, l) {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function randomColor(id) {
  const x = Math.sin(id) * 10000;
  const random = x - Math.floor(x);
  const hue = Math.trunc(random * 360);
  return hslToHex(hue, 60, 50);
}

function Chart({ leagueId }) {
  const wholeEntries = useRecoilValue(entriesQuery(leagueId));
  const games = useRecoilValue(recentGamesQuery(leagueId));
  const [customShowCount, setCustomShowCount] = useState(null);

  const theme = useTheme();
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const defaultShowCount = upLg ? 20 : upMd ? 15 : 10;
  const showCount = customShowCount || defaultShowCount;

  if (games.length === 0) {
    return null;
  }

  const reverseGames = games.slice(0, showCount).reverse();
  const data = reverseGames.map((g) => {
    const ret = { name: moment(g.createdAt).fromNow() };
    g.winners.forEach((w) => (ret[w.displayName] = w.point + g.winPoint));
    g.losers.forEach((l) => (ret[l.displayName] = l.point - g.winPoint));
    return ret;
  });

  const entries = wholeEntries.filter((e) => e.show);

  const last = { name: '현재' };
  entries.forEach((e) => (last[e.displayName] = e.point));
  data.push(last);

  // 버그로 인한 임시 수정
  // Dot이 보이지 않아서 Line에 isAnimationActive 추가 https://github.com/recharts/recharts/issues/1426
  // Legend가 그래프와 겹쳐서 Legend에 wrapperStyle 추가 https://github.com/recharts/recharts/issues/2440

  return (
    <Paper style={{ padding: '30px 30px 30px 0px' }}>
      <Box
        display="flex"
        justifyContent="flex-end"
        style={{ marginBottom: '10px' }}
      >
        <ButtonGroup size="small" aria-label="small outlined button group">
          {[10, 15, 20, 30, 50, 100].map((count) => (
            <Button
              key={count}
              variant={count === showCount ? 'contained' : 'outlined'}
              color="primary"
              onClick={() => setCustomShowCount(count)}
            >
              {count}
            </Button>
          ))}
        </ButtonGroup>
      </Box>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart height={300} data={data} margin={{ bottom: 20 }}>
          <CartesianGrid strokeDasharray="2 3" />
          <XAxis dataKey="name" padding={{ left: 20, right: 20 }} />
          <YAxis
            domain={[
              (dataMin) => Math.trunc(dataMin / 10) * 10,
              (dataMax) => (Math.trunc(dataMax / 10) + 1) * 10,
            ]}
            padding={{ top: 10, bottom: 10 }}
          />
          <Tooltip />
          <Legend wrapperStyle={{ position: 'relative' }} />
          {entries.map((entry) => (
            <Line
              key={entry.id}
              connectNulls
              type="monotone"
              dataKey={entry.displayName}
              stroke={randomColor(entry.id)}
              isAnimationActive={false}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
}

export default Chart;
