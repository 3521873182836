import { useRecoilValue } from 'recoil';
import { loggedInState } from '../../recoil/atoms';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Login from './Login';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function Page() {
  const classes = useStyles();
  const loggedIn = useRecoilValue(loggedInState);

  return (
    <>
      <Container className={classes.root} style={{ textAlign: 'center' }}>
        <Typography variant="h2">모두의 Elo</Typography>
        <br />
        <Typography variant="subtitle1">
          다른 곳에서 경기한 결과를 모두의 Elo에 기록해 보세요.
          <br />
          <Link
            href="https://namu.wiki/w/Elo%20%EB%A0%88%EC%9D%B4%ED%8C%85"
            target="_blank"
            rel="noreferrer"
          >
            Elo 레이팅
          </Link>
          에 따라 플레이어의 점수를 관리하고 자동으로 팀을 구성해 줍니다.
        </Typography>
      </Container>
      <Container component="main" maxWidth="xs">
        {!loggedIn && <Login />}
      </Container>
    </>
  );
}

export default Page;
