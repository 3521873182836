import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useRefreshLeagues } from '../../recoil/atoms';
import http from '../../lib/http';

function NewLeague({ onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const refreshLeagues = useRefreshLeagues();
  const inputEl = useRef(null);

  async function handleCreate() {
    // TODO: validation
    const displayName = inputEl.current.value;
    try {
      await http.post('/api/leagues', { displayName });
      enqueueSnackbar(`리그(${displayName})가 생성되었습니다.`, {
        variant: 'success',
      });
      refreshLeagues();
      onClose();
    } catch (err) {
      enqueueSnackbar(`리그(${displayName}) 생성에 실패하였습니다.`, {
        variant: 'error',
      });
      console.error(err);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    handleCreate();
  }

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>새 리그</DialogTitle>
        <DialogContent>
          <DialogContentText>새 리그의 이름을 입력해주세요.</DialogContentText>
          <TextField
            inputRef={inputEl}
            autoFocus
            margin="dense"
            label="이름"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleCreate} color="primary">
            생성
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NewLeague;
