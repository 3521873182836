import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import http from '../../lib/http';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { err: null };
  }

  static getDerivedStateFromError(err) {
    if (err instanceof http.Error) {
      return { err: err.body.err };
    }

    return { err: 'unknown' };
  }

  componentDidCatch(_err, _errorInfo) {
    // 콘솔 로그는 저절로 찍힌다
  }

  handleGotoLogin() {
    this.setState({ err: null });
    this.props.history.push('/');
  }

  render() {
    if (this.state.err === null) {
      return this.props.children;
    }

    if (this.state.err === 'unauthorized') {
      return (
        <Grid container direction="column" alignItems="center">
          <Grid item style={{ padding: '30px' }}>
            <Typography variant="h6">로그인이 필요한 서비스 입니다.</Typography>
          </Grid>
          <Grid item>
            <Link onClick={() => this.handleGotoLogin()}>로그인</Link>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container direction="column" alignItems="center">
        <Grid item style={{ padding: '30px' }}>
          <Typography variant="h6">
            알 수 없는 오류가 발생하였습니다.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(ErrorBoundary);
