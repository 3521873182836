import { useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { meQuery, useRefreshMe } from '../../recoil/atoms';
import validate from '../../lib/validate';
import http from '../../lib/http';

function EditDisplayName({ onClose }) {
  const me = useRecoilValue(meQuery);
  const refreshMe = useRefreshMe();
  const { enqueueSnackbar } = useSnackbar();
  const displayNameInputEl = useRef(null);
  const [invalidDisplayName, setInvalidDisplayName] = useState(false);

  async function handleUpdate() {
    const displayName = displayNameInputEl.current.value;
    const isValidDisplayName = validate.nonEmptyString(displayName);
    setInvalidDisplayName(!isValidDisplayName);

    if (!isValidDisplayName) {
      return;
    }

    try {
      await http.put(`/api/me`, {
        displayName,
      });
      enqueueSnackbar(`이름이 변경되었습니다.`, {
        variant: 'success',
      });
      refreshMe();
      onClose();
    } catch (err) {
      enqueueSnackbar(`이름을 변경할 수 없습니다`, {
        variant: 'error',
      });
      console.error(err);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    handleUpdate();
  }

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>이름 변경</DialogTitle>
        <DialogContent>
          <DialogContentText>변경할 이름을 입력해주세요.</DialogContentText>
          <TextField
            inputRef={(el) => {
              if (el) {
                el.select();
                displayNameInputEl.current = el;
              }
            }}
            autoFocus
            margin="dense"
            label="이름"
            defaultValue={me.displayName}
            fullWidth
            error={invalidDisplayName}
            helperText={invalidDisplayName && '이름을 입력하세요.'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleUpdate} color="primary">
            변경
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditDisplayName;
