import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import Moment from 'react-moment';
import 'moment/locale/ko';
import CssBaseline from '@material-ui/core/CssBaseline';
import '@fontsource/roboto';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';

Moment.globalMoment = moment;
Moment.globalLocale = 'ko';

ReactDOM.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <BrowserRouter>
      <CssBaseline />
      <RecoilRoot>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <App />
        </SnackbarProvider>
      </RecoilRoot>
    </BrowserRouter>
    ,
  </GoogleOAuthProvider>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
