import { v4 as uuidv4 } from 'uuid';

// 같은 유저일지라도 다른 탭에 열린 앱을 구분하기 위해 생성한다
const appId = uuidv4();

class HttpError extends Error {
  constructor(message, body) {
    super(message);
    this.body = body;
  }
}

async function get(url, option) {
  const headers = {};
  if (option && option.withAppId) {
    headers['Elo-App-Id'] = appId;
  }

  const res = await fetch(url, {
    method: 'GET',
    headers,
  });
  if (!res.ok) {
    const body = await res.json();
    throw new HttpError('http-error', body);
  }
  return res.json();
}

async function post(url, body, option) {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (option && option.withAppId) {
    headers['Elo-App-Id'] = appId;
  }

  const res = await fetch(url, {
    method: 'POST',
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });
  if (!res.ok) {
    const body = await res.json();
    throw new HttpError('http-error', body);
  }
  return res.json();
}

async function put(url, body, option) {
  const headers = {
    'Content-Type': 'application/json',
  };
  if (option && option.withAppId) {
    headers['Elo-App-Id'] = appId;
  }

  const res = await fetch(url, {
    method: 'PUT',
    headers,
    body: body ? JSON.stringify(body) : undefined,
  });
  if (!res.ok) {
    const body = await res.json();
    throw new HttpError('http-error', body);
  }
  return res.json();
}

async function deleteReqest(url, option) {
  const headers = {};
  if (option && option.withAppId) {
    headers['Elo-App-Id'] = appId;
  }

  const res = await fetch(url, {
    method: 'DELETE',
    headers,
  });
  if (!res.ok) {
    const body = await res.json();
    throw new HttpError('http-error', body);
  }
  return res.json();
}

const http = { appId, Error: HttpError, get, post, put, delete: deleteReqest };

export default http;
