import _ from 'lodash';
import { useState, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Nameplate from '../../common/Nameplate';
import http from '../../../lib/http';
import {
  entriesQuery,
  selectedEntryIdsState,
  useRefreshEntries,
  useRefreshGames,
  useRefreshNumGames,
} from '../../../recoil/atoms';
import match from '../../../lib/match';

function MatchTeam({ name, team, manual, onClickWin, onMoveTeam }) {
  return (
    <Grid container direction="column" style={{ border: '1px solid #eee' }}>
      <Grid
        item
        style={{
          backgroundColor: '#eef',
          padding: '10px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6">
          {name} 팀{' '}
          <span
            style={{
              padding: '3px 10px',
              backgroundColor: '#72b540',
              borderRadius: '5px',
              color: '#fff',
              fontSize: '80%',
            }}
          >
            {team.point}
          </span>
        </Typography>
        <Typography variant="body2">
          승률: {(team.winningRate * 100).toFixed(1)}% (+{team.winPoint})
        </Typography>
      </Grid>
      <Grid item style={{ padding: '10px', flexGrow: 1 }}>
        {team.member.map((m) => (
          <Grid
            container
            key={m.id}
            alignItems="center"
            justify="center"
            style={{ padding: '5px' }}
          >
            {manual && name === 'A' && (
              <>
                <Nameplate user={m} />
                <IconButton
                  style={{ margin: '-5px' }}
                  onClick={() => onMoveTeam(m.id)}
                >
                  <ArrowForwardIcon />
                </IconButton>
              </>
            )}
            {manual && name === 'B' && (
              <>
                <IconButton
                  style={{ margin: '-5px' }}
                  onClick={() => onMoveTeam(m.id)}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Nameplate user={m} />
              </>
            )}
            {!manual && (
              <>
                <Nameplate user={m} />
              </>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid item style={{ padding: '10px', textAlign: 'center' }}>
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onClickWin}
          >
            승리 기록
          </Button>
        </div>
      </Grid>
    </Grid>
    // </Paper>
  );
}

function Match({ leagueId }) {
  const entries = useRecoilValue(entriesQuery(leagueId));
  const selectedEntryIds = useRecoilValue(selectedEntryIdsState(leagueId));
  const refreshEntries = useRefreshEntries(leagueId);
  const refreshGames = useRefreshGames(leagueId);
  const refreshNumGames = useRefreshNumGames(leagueId);
  const [matchMode, setMatchMode] = useState('auto');
  const [aTeamManualIds, setATeamManualIds] = useState([]);
  const [bTeamManualIds, setBTeamManualIds] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const inputEl = useRef(null);

  const selectedEntries = selectedEntryIds.map((id) =>
    entries.find((e) => e.id === id),
  );

  let aTeam = null;
  let bTeam = null;
  if (matchMode === 'auto') {
    // 항상 같은 결과가 나오게 하기 위해 정렬한다. (다른 사람이 다른 PC에서 실행해도 같은 결과가 보이도록)
    const sortedEntries = _.sortBy(selectedEntries, ['point', 'id']);
    const matches = match.makeMatchesWithPoint(sortedEntries);
    const bestMatch = _.minBy(matches, (match) =>
      Math.abs(match[0].point - match[1].point),
    );
    aTeam = bestMatch[0];
    bTeam = bestMatch[1];
  } else if (matchMode === 'manual') {
    const newMemberIds = _.difference(
      selectedEntryIds,
      aTeamManualIds,
      bTeamManualIds,
    );
    const aTeamMemberIds = aTeamManualIds.filter((id) =>
      selectedEntryIds.includes(id),
    );
    const aTeamMember = aTeamMemberIds.map((id) =>
      entries.find((e) => e.id === id),
    );
    aTeam = {
      member: aTeamMember,
      point: aTeamMember.reduce((acc, cur) => acc + cur.point, 0),
    };
    const bTeamMemberIds = [
      ...bTeamManualIds.filter((id) => selectedEntryIds.includes(id)),
      ...newMemberIds,
    ];
    const bTeamMember = bTeamMemberIds.map((id) =>
      entries.find((e) => e.id === id),
    );
    bTeam = {
      member: bTeamMember,
      point: bTeamMember.reduce((acc, cur) => acc + cur.point, 0),
    };
  }

  const maxTeamMember = Math.max(aTeam.member.length, bTeam.member.length);

  const aTeamAvgPoint = aTeam.point / maxTeamMember;
  const bTeamAvgPoint = bTeam.point / maxTeamMember;

  aTeam.winningRate = 1 / (1 + 10 ** ((bTeamAvgPoint - aTeamAvgPoint) / 400));
  aTeam.winPoint = Math.trunc(32 * (1 - aTeam.winningRate));

  bTeam.winningRate = 1 / (1 + 10 ** ((aTeamAvgPoint - bTeamAvgPoint) / 400));
  bTeam.winPoint = Math.trunc(32 * (1 - bTeam.winningRate));

  function handleManualMatchMode() {
    setATeamManualIds(aTeam.member.map((m) => m.id));
    setBTeamManualIds(bTeam.member.map((m) => m.id));
    setMatchMode('manual');
  }

  function handleMoveTeam(id, targetTeam) {
    if (targetTeam === 'A') {
      setATeamManualIds(_.union(aTeamManualIds, [id]));
      setBTeamManualIds(_.without(bTeamManualIds, id));
    } else if (targetTeam === 'B') {
      setBTeamManualIds(_.union(bTeamManualIds, [id]));
      setATeamManualIds(_.without(aTeamManualIds, id));
    }
  }

  async function handleWin(winTeam, loseTeam) {
    try {
      await http.post(
        `/api/leagues/${leagueId}/games`,
        {
          winners: winTeam.member.map((m) => m.id),
          losers: loseTeam.member.map((m) => m.id),
        },
        { withAppId: true },
      );
      enqueueSnackbar(`경기 결과를 기록하였습니다.`, {
        variant: 'success',
      });
      refreshEntries();
      refreshGames();
      refreshNumGames();
    } catch (err) {
      enqueueSnackbar(`경기 결과 기록에 실패하였습니다.`, {
        variant: 'error',
      });
      console.error(err);
    }
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <Paper style={{ padding: '30px' }}>
          <Grid container direction="row" justify="center" alignItems="center">
            <ButtonGroup
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button
                variant={matchMode === 'auto' && 'contained'}
                onClick={() => setMatchMode('auto')}
              >
                자동 구성
              </Button>
              <Button
                variant={matchMode === 'manual' && 'contained'}
                onClick={handleManualMatchMode}
              >
                수동 구성
              </Button>
            </ButtonGroup>
          </Grid>
          <br />
          <Grid container direction="row" justify="center" alignItems="stretch">
            <Grid container item xs={12} sm={5}>
              <MatchTeam
                name="A"
                team={aTeam}
                manual={matchMode === 'manual'}
                onMoveTeam={(id) => handleMoveTeam(id, 'B')}
                onClickWin={() => handleWin(aTeam, bTeam)}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={2}
              justify="center"
              alignItems="center"
            >
              <Typography variant="h6">vs.</Typography>
            </Grid>
            <Grid container item xs={12} sm={5}>
              <MatchTeam
                name="B"
                team={bTeam}
                manual={matchMode === 'manual'}
                onMoveTeam={(id) => handleMoveTeam(id, 'A')}
                onClickWin={() => handleWin(bTeam, aTeam)}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container direction="row" justify="center" alignItems="center">
            <TextField
              inputRef={inputEl}
              variant="outlined"
              size="small"
              value={`${aTeam.member
                .map((m) => m.displayName)
                .join(', ')} vs. ${bTeam.member
                .map((m) => m.displayName)
                .join(', ')}`}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                inputEl.current.select();
                document.execCommand('copy');
              }}
            >
              복사
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Match;
