import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import EditDisplayName from './EditDisplayName';
import { meQuery } from '../../recoil/atoms';

function Page() {
  const [openEditDisplayName, setOpenEditDisplayName] = useState(false);
  const me = useRecoilValue(meQuery);

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Typography
          component="span"
          variant="h5"
          style={{ marginRight: '20px' }}
        >
          내 정보
        </Typography>
      </div>
      <Grid container style={{ marginBottom: '30px' }} spacing={2}>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                이름
              </Typography>
              <Box display="flex" flexDirection="row">
                <Box
                  flexGrow={1}
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <Typography variant="h5" component="h2">
                    {me.displayName}
                  </Typography>
                </Box>
                <IconButton
                  style={{ margin: '-12px -12px -12px 0px' }}
                  onClick={() => setOpenEditDisplayName(true)}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                이메일
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {me.email}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                연결 계정
              </Typography>
              <Typography variant="h5" component="h2">
                {me.googleId && '구글'}
                {me.facebookId && '페이스북'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {openEditDisplayName && (
        <EditDisplayName onClose={() => setOpenEditDisplayName(false)} />
      )}
    </div>
  );
}

export default Page;
