import _ from 'lodash';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from 'react-moment';
import ConfirmDialog from '../../common/ConfirmDialog';
import Nameplate from '../../common/Nameplate';
import {
  gamesQuery,
  useRefreshEntries,
  useRefreshGames,
  useRefreshNumGames,
  useLoadMoreGames,
} from '../../../recoil/atoms';
import http from '../../../lib/http';

function Game({ game, onDelete }) {
  return (
    <Grid key={game.id} container spacing={0} style={{ marginBottom: '10px' }}>
      <Grid item xs={1} />
      <Grid item xs={10} container justify="center">
        <Tooltip title={moment(game.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
          <Moment fromNow>{game.createdAt}</Moment>
        </Tooltip>
      </Grid>
      <Grid item xs={1} style={{ textAlign: 'right' }}>
        {onDelete && (
          <IconButton
            size="small"
            style={{ margin: '-5px' }}
            onClick={onDelete}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={6}>
        <div
          style={{
            marginRight: '5px',
          }}
        >
          <div
            style={{
              padding: '5px',
              backgroundColor: '#eef',
              textAlign: 'center',
            }}
          >
            승리 +{game.winPoint}
          </div>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              border: '1px solid #eee',
              padding: '0px 10px 10px 10px',
            }}
          >
            {game.winners.map((player) => (
              <div key={player.entryId} style={{ marginTop: '10px' }}>
                <Nameplate user={player} />{' '}
              </div>
            ))}
          </Grid>
        </div>
      </Grid>
      <Grid item xs={6}>
        <div
          style={{
            marginLeft: '5px',
          }}
        >
          {' '}
          <div
            style={{
              padding: '5px',
              backgroundColor: '#eef',
              textAlign: 'center',
            }}
          >
            패배 -{game.winPoint}
          </div>
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{
              border: '1px solid #eee',
              padding: '0px 10px 10px 10px',
            }}
          >
            {game.losers.map((player) => (
              <div key={player.entryId} style={{ marginTop: '10px' }}>
                <Nameplate user={player} />{' '}
              </div>
            ))}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

function Games({ leagueId }) {
  const loadables = useRecoilValue(gamesQuery(leagueId));
  const refreshEntries = useRefreshEntries(leagueId);
  const loadMoreGames = useLoadMoreGames(leagueId);
  const refreshGames = useRefreshGames(leagueId);
  const refreshNumGames = useRefreshNumGames(leagueId);
  const [deleteConfirmId, setDeleteConfirmId] = useState(null);
  const loadMoreRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const recentGameId =
    loadables[0] && loadables[0].state === 'hasValue'
      ? loadables[0].contents?.list?.[0]?.id
      : null;
  const allHasValue = loadables.every(
    (loadable) => loadable.state === 'hasValue',
  );
  const showLoadMore = allHasValue && _.last(loadables).contents.remain > 0;

  useEffect(() => {
    const callback = async (entries, observer) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          // 이벤트가 계속 발생하지 않도록 바로 disconnect 한다.
          // loadMore에 의해 loadables가 갱신되면 다시 useEffect가 불려서 observe하게 된다.
          observer.disconnect();
          loadMoreGames();

          // 한 번에 요청은 하나만 보내도록 return 한다.
          return;
        }
      }
    };
    const observer = new IntersectionObserver(callback);
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [loadables, loadMoreGames]);

  async function handleDelete() {
    setDeleteConfirmId(null);

    try {
      await http.delete(`/api/leagues/${leagueId}/games/${deleteConfirmId}`, {
        withAppId: true,
      });
      enqueueSnackbar(`최근 경기를 삭제 하였습니다.`, {
        variant: 'success',
      });
      refreshEntries();
      refreshGames();
      refreshNumGames();
    } catch (err) {
      enqueueSnackbar(`최근 경기 삭제에 실패하였습니다.`, {
        variant: 'error',
      });
      console.error(err);
    }
  }

  function renderLoadable(loadables) {
    let curDate = null;
    const components = [];
    for (const [cursor, loadable] of loadables.entries()) {
      switch (loadable.state) {
        case 'hasValue':
          for (const game of loadable.contents.list) {
            const datePart = moment(game.createdAt).format('YYYYMMDD');
            if (curDate !== datePart) {
              curDate = datePart;
              components.push(
                <Grid
                  key={datePart}
                  container
                  justify="center"
                  style={{
                    marginBottom: '5px',
                    padding: '5px',
                    backgroundColor: '#11bd9d',
                    borderRadius: '5px',
                  }}
                >
                  {moment(game.createdAt).format('ll')}
                </Grid>,
              );
            }
            components.push(
              <Game
                key={game.id}
                game={game}
                onDelete={
                  game.id === recentGameId &&
                  (() => setDeleteConfirmId(game.id))
                }
              />,
            );
          }
          break;
        case 'hasError':
          components.push(<Grid key={`cursor-${cursor}`}>에러</Grid>);
          break;
        case 'loading':
          components.push(<Grid key={`cursor-${cursor}`}>로딩</Grid>);
          break;
        default:
          components.push(
            <Grid key={`cursor-${cursor}`}>{loadable.state}</Grid>,
          );
      }
    }

    return components;
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                {renderLoadable(loadables)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog
        open={deleteConfirmId !== null}
        title="이 기록을 삭제하겠습니까?"
        content="이 기록을 삭제 하면 참가자들의 점수가 이전 상태로 복구 됩니다."
        ok="삭제"
        cancel="취소"
        onOk={handleDelete}
        onCancel={() => setDeleteConfirmId(null)}
      />
      {showLoadMore && (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ padding: '30px' }}
        >
          <CircularProgress ref={loadMoreRef} />
        </Grid>
      )}
    </div>
  );
}

export default Games;
