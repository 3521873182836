import { useState, useEffect, Suspense } from 'react';
import {
  Switch,
  Route,
  Link as RouterLink,
  useHistory,
} from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import http from '../lib/http';
import { loggedInState } from '../recoil/atoms';
import ErrorBoundary from './common/ErrorBoundary';
import HomePage from './Home/Page';
import Profile from './Profile/Page';
import LeaguesPage from './Leagues/Page';
import LeaguePage from './Leagues/League/Page';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(1),
  },
  text: {
    flexGrow: 1,
  },
  title: {
    display: 'inline',
    marginRight: '30px',
  },
  link: {
    display: 'inline',
    '& a:hover': {
      textDecoration: 'none',
    },
  },
}));

function App() {
  const classes = useStyles();
  const history = useHistory();
  const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        await http.get('/api/me');
        setLoggedIn(true);
      } catch (err) {
        setLoggedIn(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoggedIn]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setAnchorEl(null);
    history.push('/profile');
  };

  async function handleLogout() {
    setAnchorEl(null);
    try {
      await http.post('/api/auth/logout');
    } catch (err) {
      console.error(err);
    } finally {
      setLoggedIn(false);
      history.push('/');
    }
  }

  if (loading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <AppBar position="static">
        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              component={RouterLink}
              to="/"
            >
              <HomeIcon />
            </IconButton>
            <div className={classes.text}>
              <Typography variant="h6" className={classes.title}>
                모두의 Elo
              </Typography>
              {loggedIn && (
                <Typography variant="subtitle2" className={classes.link}>
                  <Link component={RouterLink} to="/leagues" color="inherit">
                    리그
                  </Link>
                </Typography>
              )}
            </div>
            {loggedIn && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleProfile}>내 정보</MenuItem>
                  <MenuItem onClick={handleLogout}>로그 아웃</MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Container
        maxWidth="lg"
        style={{ marginTop: '30px', marginBottom: '30px' }}
      >
        <ErrorBoundary>
          <Switch>
            <Route path="/profile">
              <Suspense fallback={<CircularProgress />}>
                <Profile />
              </Suspense>
            </Route>
            <Route exact path="/leagues">
              <LeaguesPage />
            </Route>
            <Route path="/leagues/:id">
              <Suspense fallback={<CircularProgress />}>
                <LeaguePage />
              </Suspense>
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </ErrorBoundary>
      </Container>
    </div>
  );
}

export default App;
