import moment from 'moment';
import { Suspense, useState, useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import NewEntry from './NewEntry';
import NewVirtualEntry from './NewVirtualEntry';
import Match from './Match';
import Chart from './Chart';
import Entries from './Entries';
import Games from './Games';
import {
  leagueQuery,
  watchersState,
  entriesQuery,
  useRefreshEntries,
  selectedEntryIdsState,
  useRefreshGames,
  numEntriesQuery,
  numGamesQuery,
  useRefreshNumEntries,
} from '../../../recoil/atoms';
import http from '../../../lib/http';

function CreatorDisplayName({ leagueId, creatorId, defaultValue }) {
  const entries = useRecoilValue(entriesQuery(leagueId));
  const creatorEntry = entries.find((e) => e.userId === creatorId);
  return creatorEntry ? creatorEntry.displayName : defaultValue;
}

function NumEntries({ leagueId }) {
  return useRecoilValue(numEntriesQuery(leagueId));
}

function NumGames({ leagueId }) {
  return useRecoilValue(numGamesQuery(leagueId));
}

function Page() {
  const id = parseInt(useParams().id, 10);
  const league = useRecoilValue(leagueQuery(id));
  const refreshEntries = useRefreshEntries(id);
  const refreshNumEntries = useRefreshNumEntries(id);
  const setWatchers = useSetRecoilState(watchersState(id));
  const selectedEntryIds = useRecoilValue(selectedEntryIdsState(id));
  const refreshGames = useRefreshGames(id);
  const [openNewEntry, setOpenNewEntry] = useState(false);
  const [openNewVirtualEntry, setOpenNewVirtualEntry] = useState(false);
  const [showRefreshGame, setShowRefreshGame] = useState(false);
  const [watchGames, setWatchGames] = useState(false);
  const ws = useRef(null);

  useEffect(() => {
    const url =
      process.env.NODE_ENV === 'development'
        ? 'ws://localhost:3010'
        : window.location.origin.replace(/^http/, 'ws');
    ws.current = new WebSocket(`${url}/api/appId/${http.appId}`);
    ws.current.onopen = (event) => {
      console.log('ws-open', event);
    };
    ws.current.onclose = (event) => {
      console.log('ws-close', event);
      setWatchGames(false);
      setWatchers([]);
    };
    ws.current.onerror = (error) => {
      console.error('ws-error', error);
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      switch (message.type) {
        case 'ready':
          ws.current.send(JSON.stringify({ type: 'watch-league', id }));
          setWatchGames(true);
          break;
        case 'new-game':
          setShowRefreshGame(true);
          break;
        case 'delete-game':
          setShowRefreshGame(true);
          break;
        case 'watchers':
          setWatchers(message.watchers);
          break;
        default:
          console.log('ws-message', message);
      }
    };
    return () => {
      if (ws.current) {
        // console.log('ws-close');
        ws.current.close();
      }
    };
  }, [id, setWatchers]);

  function handleCreateEntry() {
    setOpenNewEntry(false);
    refreshEntries();
    refreshNumEntries();
  }

  function handleCreateVirtualEntry() {
    setOpenNewVirtualEntry(false);
    refreshEntries();
    refreshNumEntries();
  }

  function handleRefreshGames() {
    setShowRefreshGame(false);
    refreshGames();
  }

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Typography
          component="span"
          variant="h5"
          style={{ marginRight: '20px' }}
        >
          {league.displayName}
        </Typography>
        <Link
          style={{ marginRight: '20px' }}
          onClick={() => setOpenNewEntry(true)}
        >
          새 참가자
        </Link>
        <Link onClick={() => setOpenNewVirtualEntry(true)}>새 가상 참가자</Link>
      </div>
      {openNewEntry && (
        <NewEntry
          leagueId={id}
          onCreate={handleCreateEntry}
          onClose={() => setOpenNewEntry(false)}
        />
      )}
      {openNewVirtualEntry && (
        <NewVirtualEntry
          leagueId={id}
          onCreate={handleCreateVirtualEntry}
          onClose={() => setOpenNewVirtualEntry(false)}
        />
      )}

      <Grid container style={{ marginBottom: '30px' }} spacing={2}>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                주최자
              </Typography>
              <Typography
                variant="h5"
                component="h2"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                <Suspense fallback="">
                  <CreatorDisplayName
                    leagueId={id}
                    creatorId={league.creatorId}
                    defaultValue={league.creatorDisplayName}
                  />
                </Suspense>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                생성일
              </Typography>
              <Typography variant="h5" component="h2">
                {moment(league.createdAt).format('YYYY-MM-DD')}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                참가자
              </Typography>
              <Typography variant="h5" component="h2">
                <Suspense fallback="?">
                  <NumEntries leagueId={id} />
                </Suspense>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                경기
              </Typography>
              <Typography variant="h5" component="h2">
                <Suspense fallback="?">
                  <NumGames leagueId={id} />
                </Suspense>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <div style={{ marginBottom: '30px' }}>
        <Suspense fallback={<CircularProgress />}>
          <Chart leagueId={id} />
        </Suspense>
      </div>

      <div style={{ marginBottom: '30px' }}>
        <Suspense fallback={<CircularProgress />}>
          <Entries leagueId={id} />
        </Suspense>
      </div>

      {selectedEntryIds.length === 1 && (
        <Paper
          style={{ marginBottom: '30px', padding: '30px', textAlign: 'center' }}
        >
          팀을 구성하기 위해 최소 2명의 참가자를 선택해 주세요.
        </Paper>
      )}

      {selectedEntryIds.length > 1 && (
        <div style={{ marginBottom: '30px' }}>
          <Suspense fallback={<CircularProgress />}>
            <Match leagueId={id} />
          </Suspense>
        </div>
      )}

      {showRefreshGame && (
        <Grid container justify="center" style={{ marginBottom: '30px' }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleRefreshGames}
          >
            새로운 경기 기록이 추가되었습니다. 여기를 눌러 최신 상태로
            갱신하세요.
          </Button>
        </Grid>
      )}

      {!showRefreshGame && watchGames && (
        <Grid container justify="center" style={{ marginBottom: '30px' }}>
          <Button variant="outlined" color="primary" disabled>
            새로운 경기 기록이 생기면 여기에 표시됩니다.
          </Button>
        </Grid>
      )}

      <div>
        <Suspense fallback={<CircularProgress />}>
          <Games leagueId={id} />
        </Suspense>
      </div>
    </div>
  );
}

export default Page;
