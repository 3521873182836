import { useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import validate from '../../../lib/validate';
import http from '../../../lib/http';

function NewEntry({ leagueId, onCreate, onClose }) {
  const { enqueueSnackbar } = useSnackbar();
  const emailInputEl = useRef(null);
  const pointInputEl = useRef(null);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPoint, setInvalidPoint] = useState(false);

  async function handleCreate() {
    let allValid = true;

    const email = emailInputEl.current.value;
    const isValidEmail = validate.email(email);
    setInvalidEmail(!isValidEmail);
    allValid = allValid && isValidEmail;

    const point = pointInputEl.current.value;
    const isValidPoint = validate.integerGreaterThanOrEqualToZero(point);
    setInvalidPoint(!isValidPoint);
    allValid = allValid && isValidPoint;

    if (!allValid) {
      return;
    }

    try {
      await http.post(`/api/leagues/${leagueId}/entries`, {
        email,
        point,
      });
      enqueueSnackbar(`참가자(${email})가 등록되었습니다.`, {
        variant: 'success',
      });
      onCreate();
    } catch (err) {
      if (err instanceof http.Error && err.body.err === 'user-not-found') {
        enqueueSnackbar(`사용자를 찾을 수 없습니다.`, {
          variant: 'error',
        });
      } else if (
        err instanceof http.Error &&
        err.body.err === 'user-already-exists'
      ) {
        enqueueSnackbar(`이미 참가중인 사용자입니다.`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(`참가자(${email}) 추가에 실패하였습니다.`, {
          variant: 'error',
        });
        console.error(err);
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    handleCreate();
  }

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>새 참가자</DialogTitle>
        <DialogContent>
          <DialogContentText>
            새 참가자의 이메일을 입력해주세요.
          </DialogContentText>
          <TextField
            inputRef={emailInputEl}
            autoFocus
            margin="dense"
            label="이메일"
            fullWidth
            error={invalidEmail}
            helperText={invalidEmail && '이메일 형식에 맞지 않습니다.'}
          />
          <TextField
            inputRef={pointInputEl}
            defaultValue={1500}
            margin="dense"
            label="시작 점수"
            fullWidth
            error={invalidPoint}
            helperText={invalidPoint && '0 이상의 정수를 입력해 주세요.'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button type="submit" color="primary">
            추가
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NewEntry;
