import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useRefreshEntries } from '../../../recoil/atoms';
import http from '../../../lib/http';

function EditEntryName({ leagueId, entryId, defaultValue, onClose }) {
  const refreshEntries = useRefreshEntries(leagueId);
  const { enqueueSnackbar } = useSnackbar();
  const inputEl = useRef(null);

  async function handleUpdate() {
    // TODO: validation
    const displayName = inputEl.current.value;
    try {
      await http.put(`/api/leagues/${leagueId}/entries/${entryId}`, {
        displayName,
      });
      enqueueSnackbar(`표시명이 변경되었습니다.`, {
        variant: 'success',
      });
      refreshEntries();
      onClose();
    } catch (err) {
      enqueueSnackbar(`표시명을 변경할 수 없습니다`, {
        variant: 'error',
      });
      console.error(err);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    handleUpdate();
  }

  return (
    <Dialog open onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>표시명 변경</DialogTitle>
        <DialogContent>
          <DialogContentText>
            이 리그에서 사용할 표시명을 입력해주세요.
          </DialogContentText>
          <TextField
            inputRef={(el) => {
              if (el) {
                el.select();
                inputEl.current = el;
              }
            }}
            autoFocus
            margin="dense"
            label="표시명"
            defaultValue={defaultValue}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            취소
          </Button>
          <Button onClick={handleUpdate} color="primary">
            변경
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditEntryName;
