import { Suspense, useState } from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Leagues from './Leagues';
import NewLeague from './NewLeague';

function Page() {
  const [openNewLeague, setOpenNewLeague] = useState(false);

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <Typography
          component="span"
          variant="h5"
          style={{ marginRight: '20px' }}
        >
          리그
        </Typography>
        <Link onClick={() => setOpenNewLeague(true)}>새 리그</Link>
      </div>
      {openNewLeague && <NewLeague onClose={() => setOpenNewLeague(false)} />}
      <Suspense fallback={<CircularProgress />}>
        <Leagues />
      </Suspense>
    </div>
  );
}

export default Page;
