export function nonEmptyString(str) {
  return str.trim() !== '';
}

export function email(email) {
  const re =
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(email);
}

export function integerGreaterThanOrEqualToZero(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str);
}

const validate = { nonEmptyString, email, integerGreaterThanOrEqualToZero };

export default validate;
