import _ from 'lodash';
import { useState } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditEntryName from './EditEntryName';
import {
  meQuery,
  entriesQuery,
  useRefreshEntries,
  useRefreshNumEntries,
  selectedEntryIdsState,
  watchersState,
} from '../../../recoil/atoms';
import http from '../../../lib/http';

function ActionMenu({ leagueId, entry }) {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedEntryIds, setSelectedEntryIds] = useRecoilState(
    selectedEntryIdsState(leagueId),
  );
  const refreshEntries = useRefreshEntries(leagueId);
  const refreshNumEntries = useRefreshNumEntries(leagueId);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setAnchorEl(null);

    try {
      await http.delete(`/api/leagues/${leagueId}/entries/${entry.id}`);
      setSelectedEntryIds(_.without(selectedEntryIds, entry.id));
      enqueueSnackbar(`참가자(${entry.displayName})를 제외하였습니다.`, {
        variant: 'success',
      });
      refreshEntries();
      refreshNumEntries();
    } catch (err) {
      if (err instanceof http.Error && err.body.err === 'user-played-game') {
        enqueueSnackbar(`경기에 참여했던 참가자는 제외시킬 수 없습니다.`, {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(`참가자(${entry.displayName}) 제외에 실패하였습니다.`, {
          variant: 'error',
        });
      }
      console.error(err);
    }
  };

  const handleHide = async () => {
    setAnchorEl(null);

    try {
      await http.put(`/api/leagues/${leagueId}/entries/${entry.id}/hide`);
      setSelectedEntryIds(_.without(selectedEntryIds, entry.id));
      enqueueSnackbar(`참가자(${entry.displayName})를 숨겼습니다.`, {
        variant: 'success',
      });
      refreshEntries();
      refreshNumEntries();
    } catch (err) {
      enqueueSnackbar(`참가자(${entry.displayName}) 숨기기에 실패하였습니다.`, {
        variant: 'error',
      });
      console.error(err);
    }
  };

  const handleShow = async () => {
    setAnchorEl(null);

    try {
      await http.put(`/api/leagues/${leagueId}/entries/${entry.id}/show`);
      setSelectedEntryIds(_.without(selectedEntryIds, entry.id));
      enqueueSnackbar(`참가자(${entry.displayName})를 다시 표시합니다.`, {
        variant: 'success',
      });
      refreshEntries();
      refreshNumEntries();
    } catch (err) {
      enqueueSnackbar(
        `참가자(${entry.displayName})를 다시 표시하는데 실패하였습니다.`,
        {
          variant: 'error',
        },
      );
      console.error(err);
    }
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleDelete}>삭제</MenuItem>
        {entry.show && <MenuItem onClick={handleHide}>숨기기</MenuItem>}
        {!entry.show && <MenuItem onClick={handleShow}>표시</MenuItem>}
      </Menu>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
}));

function Entries({ leagueId }) {
  const classes = useStyles();
  const me = useRecoilValue(meQuery);
  const wholeEntries = useRecoilValue(entriesQuery(leagueId));
  const [selectedEntryIds, setSelectedEntryIds] = useRecoilState(
    selectedEntryIdsState(leagueId),
  );
  const watchers = useRecoilValue(watchersState(leagueId));
  const [showHiddenEntries, setShowHiddenEntries] = useState(false);
  const [openEditEntryName, setOpenEditEntryName] = useState(false);

  const entries = showHiddenEntries
    ? wholeEntries
    : wholeEntries.filter((e) => e.show);

  const ToggleHiddenEntries = wholeEntries.some((e) => !e.show) && (
    <Box style={{ margin: '5px', fontSize: '12px', textAlign: 'right' }}>
      {!showHiddenEntries && (
        <Link onClick={() => setShowHiddenEntries(true)}>숨긴 사용자 표시</Link>
      )}
      {showHiddenEntries && (
        <Link onClick={() => setShowHiddenEntries(false)}>
          숨긴 사용자 감추기
        </Link>
      )}
    </Box>
  );

  if (entries.length === 0) {
    return (
      <div style={{ marginBottom: '30px' }}>
        <Paper style={{ padding: '30px', textAlign: 'center' }}>
          참가자를 추가해 주세요.
        </Paper>
        {ToggleHiddenEntries}
      </div>
    );
  }

  const myEntry = entries.find((e) => e.userId === me.id);

  function handleCheck(checked, entryId) {
    if (checked) {
      setSelectedEntryIds(_.union(selectedEntryIds, [entryId]));
    } else {
      setSelectedEntryIds(_.without(selectedEntryIds, entryId));
    }
  }

  function handleCheckAll(e) {
    if (e.target.checked) {
      const selectedEntryIds = entries.map((e) => e.id);
      setSelectedEntryIds(selectedEntryIds);
      return;
    }
    setSelectedEntryIds([]);
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell
                style={{
                  width: '50px',
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <Checkbox
                  indeterminate={
                    selectedEntryIds.length > 0 &&
                    selectedEntryIds.length < entries.length
                  }
                  checked={
                    entries.length > 0 &&
                    selectedEntryIds.length === entries.length
                  }
                  onChange={handleCheckAll}
                />
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{
                  width: '50px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  textAlign: 'right',
                }}
              >
                점수
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{
                  width: '50px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  textAlign: 'right',
                }}
              >
                승
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{
                  width: '50px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  textAlign: 'right',
                }}
              >
                패
              </TableCell>
              <TableCell component="th" scope="row">
                참가자
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ width: '10%', padding: '3px', textAlign: 'right' }}
              ></TableCell>
            </TableRow>
            {entries.map((entry) => (
              <TableRow key={entry.id} className={classes.row}>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    width: '50px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                >
                  <Checkbox
                    checked={selectedEntryIds.includes(entry.id)}
                    onChange={(e) => handleCheck(e.target.checked, entry.id)}
                  />
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    width: '50px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    textAlign: 'right',
                  }}
                >
                  {entry.point}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    width: '50px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    textAlign: 'right',
                  }}
                >
                  {entry.win} 승
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{
                    width: '50px',
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    textAlign: 'right',
                  }}
                >
                  {entry.lose} 패
                </TableCell>
                <TableCell component="th" scope="row">
                  <span style={{ marginRight: '10px' }}>
                    {watchers.some((watcher) => watcher.userId === entry.userId)
                      ? '🟢'
                      : '⚪'}
                  </span>
                  {entry.displayName}
                  {entry.userId === null && (
                    <span
                      style={{
                        marginLeft: '10px',
                        color: '#aaa',
                        fontSize: '12px',
                      }}
                    >
                      (가상 참가자)
                    </span>
                  )}
                  {!entry.show && (
                    <span
                      style={{
                        marginLeft: '10px',
                        color: '#aaa',
                        fontSize: '12px',
                      }}
                    >
                      (숨겨짐)
                    </span>
                  )}
                  {entry.userId === me.id && (
                    <Link
                      style={{ marginLeft: '10px' }}
                      onClick={() => setOpenEditEntryName(true)}
                    >
                      ⬅ 표시명 변경
                    </Link>
                  )}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ width: '10%', padding: '3px', textAlign: 'right' }}
                >
                  <ActionMenu leagueId={leagueId} entry={entry} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {ToggleHiddenEntries}
      {openEditEntryName && (
        <EditEntryName
          leagueId={leagueId}
          entryId={myEntry.id}
          defaultValue={myEntry.displayName}
          onClose={() => setOpenEditEntryName(false)}
        />
      )}
    </div>
  );
}

export default Entries;
