import _ from 'lodash';
import {
  atom,
  selector,
  atomFamily,
  selectorFamily,
  useSetRecoilState,
  waitForNone,
} from 'recoil';
import http from '../lib/http';

export const loggedInState = atom({
  key: 'loggedInState',
  default: false,
});

// 모든 요청을 invalidate 시키기 위한 base atom
export const requestIdState = atom({
  key: 'requestId',
  default: 0,
});

export function useRefreshAll() {
  const setRequestIdState = useSetRecoilState(requestIdState);
  return () => {
    setRequestIdState((requestId) => requestId + 1);
  };
}

// me

export const meQueryRequestIdState = atom({
  key: 'meQueryRequestId',
  default: 0,
});

export const meQuery = selector({
  key: 'meQuery',
  get: async ({ get }) => {
    get(requestIdState);
    get(meQueryRequestIdState);
    return await http.get(`/api/me`);
  },
});

export function useRefreshMe() {
  const setMeQueryRequestId = useSetRecoilState(meQueryRequestIdState);
  return () => {
    setMeQueryRequestId((requestId) => requestId + 1);
  };
}

// leagues

export const leaguesQueryRequestIdState = atom({
  key: 'leaguesQueryRequestId',
  default: 0,
});

export const leaguesQuery = selector({
  key: 'leaguesQuery',
  get: async ({ get }) => {
    get(requestIdState);
    get(leaguesQueryRequestIdState);
    return await http.get(`/api/leagues`);
  },
});

export function useRefreshLeagues() {
  const setLeaguesQueryRequestId = useSetRecoilState(
    leaguesQueryRequestIdState,
  );
  return () => {
    setLeaguesQueryRequestId((requestId) => requestId + 1);
  };
}

// league

export const leagueQueryRequestIdState = atomFamily({
  key: 'leagueQueryRequestId',
  default: 0,
});

export const leagueQuery = selectorFamily({
  key: 'leagueQuery',
  get:
    (leagueId) =>
    async ({ get }) => {
      get(requestIdState);
      get(leagueQueryRequestIdState(leagueId));
      return await http.get(`/api/leagues/${leagueId}`);
    },
});

export function useRefreshLeague(leagueId) {
  const setLeagueQueryRequestId = useSetRecoilState(
    leagueQueryRequestIdState(leagueId),
  );
  return () => {
    setLeagueQueryRequestId((requestId) => requestId + 1);
  };
}

// entries

// 원래 watcher의 목적은 리그 페이지를 보고 있는 모든 유저를 표시하려고 했다.
// 해당 유저는 참가자가 이닐 수도 있고, 한 유저가 여러 탭을 열어서 보고 있을 수도 있다.
// 하지만 해당 UI를 예쁘게 만들기가 어려워서 일단 참가자만 대상으로 옆에 온라인 표시를 한다.
// 추후 UI를 새로 만들거나 서버를 참여자만 주도록 변경해야 한다.
export const watchersState = atomFamily({
  key: 'watchers',
  default: [],
});

export const selectedEntryIdsState = atomFamily({
  key: 'selectedEntryIds',
  default: [],
});

export const entriesQueryRequestIdState = atomFamily({
  key: 'entriesQueryRequestId',
  default: 0,
});

export const entriesQuery = selectorFamily({
  key: 'entriesQuery',
  get:
    (leagueId) =>
    async ({ get }) => {
      get(requestIdState);
      get(entriesQueryRequestIdState(leagueId));
      return await http.get(`/api/leagues/${leagueId}/entries`);
    },
});

export function useRefreshEntries(leagueId) {
  const setEntriesQueryRequestId = useSetRecoilState(
    entriesQueryRequestIdState(leagueId),
  );
  return () => {
    setEntriesQueryRequestId((requestId) => requestId + 1);
  };
}

export const numEntriesQueryRequestIdState = atomFamily({
  key: 'numEntriesQueryRequestId',
  default: 0,
});

export const numEntriesQuery = selectorFamily({
  key: 'numEntriesQuery',
  get:
    (leagueId) =>
    async ({ get }) => {
      get(requestIdState);
      get(numEntriesQueryRequestIdState(leagueId));
      const { count } = await http.get(
        `/api/leagues/${leagueId}/entries/count`,
      );
      return count;
    },
});

export function useRefreshNumEntries(leagueId) {
  const setNumEntriesQueryRequestId = useSetRecoilState(
    numEntriesQueryRequestIdState(leagueId),
  );
  return () => {
    setNumEntriesQueryRequestId((requestId) => requestId + 1);
  };
}

// games
// 무한 스크롤을 위해 다음 내용 참고: https://github.com/facebookexperimental/Recoil/issues/765

const gamesQueryRequestIdState = atomFamily({
  key: 'gamesQueryRequestId',
  default: 0,
});

const gamesQueryCursorState = atomFamily({
  key: 'gamesQueryCursor',
  default: 1,
});

export const recentGamesQuery = selectorFamily({
  key: 'recentGamesQuery',
  get:
    (leagueId) =>
    async ({ get }) => {
      get(requestIdState);
      get(gamesQueryRequestIdState(leagueId));
      return (await http.get(`/api/leagues/${leagueId}/games?limit=100`)).list;
    },
});

const gamesQueryParamsState = selectorFamily({
  key: 'gamesQueryParams',
  get:
    (leagueId) =>
    ({ get }) => {
      const requestId = get(gamesQueryRequestIdState(leagueId));
      const cursor = get(gamesQueryCursorState(leagueId));
      return { requestId, cursor };
    },
  set:
    (leagueId) =>
    ({ get, set }, { requestId, cursor }) => {
      set(gamesQueryRequestIdState(leagueId), requestId);
      set(gamesQueryCursorState(leagueId), cursor);
    },
});

const gamesWithCursorQuery = selectorFamily({
  key: 'gamesWithCursorQuery',
  get:
    ({ leagueId, cursor }) =>
    async ({ get }) => {
      get(requestIdState);
      get(gamesQueryRequestIdState(leagueId));
      if (cursor === 0) {
        return await http.get(`/api/leagues/${leagueId}/games`);
      }
      const prevGames = get(
        gamesWithCursorQuery({ leagueId, cursor: cursor - 1 }),
      );
      const lastGame = _.last(prevGames.list);
      return await http.get(
        `/api/leagues/${leagueId}/games?beforeId=${lastGame.id}`,
      );
    },
});

export function useLoadMoreGames(leagueId) {
  const setGamesQueryCursor = useSetRecoilState(
    gamesQueryCursorState(leagueId),
  );
  return () => setGamesQueryCursor((cursor) => cursor + 1);
}

export function useRefreshGames(leagueId) {
  const setGamesQueryParams = useSetRecoilState(
    gamesQueryParamsState(leagueId),
  );
  return () =>
    setGamesQueryParams(({ requestId }) => ({
      requestId: requestId + 1,
      cursor: 1,
    }));
}

export const gamesQuery = selectorFamily({
  key: 'gamesQuery',
  get:
    (leagueId) =>
    ({ get }) => {
      const { cursor } = get(gamesQueryParamsState(leagueId));
      const cursorRange = Array.from(Array(cursor).keys());
      return get(
        waitForNone(
          cursorRange.map((c) => gamesWithCursorQuery({ leagueId, cursor: c })),
        ),
      );
    },
});

export const numGamesQueryRequestIdState = atomFamily({
  key: 'numGamesQueryRequestId',
  default: 0,
});

export const numGamesQuery = selectorFamily({
  key: 'numGamesQuery',
  get:
    (leagueId) =>
    async ({ get }) => {
      get(requestIdState);
      get(numGamesQueryRequestIdState(leagueId));
      const { count } = await http.get(`/api/leagues/${leagueId}/games/count`);
      return count;
    },
});

export function useRefreshNumGames(leagueId) {
  const setNumGamesQueryRequestId = useSetRecoilState(
    numGamesQueryRequestIdState(leagueId),
  );
  return () => {
    setNumGamesQueryRequestId((requestId) => requestId + 1);
  };
}
