import _ from 'lodash';

export function combinations(n, r) {
  if (r < 0) {
    throw new Error('r-must-greater-than-or-equal-to-0');
  }
  if (r > n) {
    throw new Error('n-must-greater-than-or-equal-to-r');
  }
  const result = [];

  function combinationsInternal(data, start, end, index) {
    if (index === r) {
      result.push(data);
    }

    for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
      combinationsInternal([...data, i], i + 1, end, index + 1);
    }
  }

  combinationsInternal([], 0, n - 1, 0);
  return result;
}

export function makeMatches(n) {
  const teams = combinations(n, Math.trunc(n / 2));
  const participants = [...Array(n).keys()];
  const matches = [];
  for (const team of teams) {
    if (matches.some((match) => _.isEqual(match[1], team))) {
      continue;
    }
    const enemy = _.difference(participants, team);
    matches.push([team, enemy]);
  }
  return matches;
}

export function makeMatchesWithPoint(entries) {
  const matches = makeMatches(entries.length);
  return matches.map((match) => {
    const aTeamMember = match[0].map((i) => entries[i]);
    const aTeamPoint = aTeamMember.reduce((acc, cur) => acc + cur.point, 0);
    const bTeamMember = match[1].map((i) => entries[i]);
    const bTeamPoint = bTeamMember.reduce((acc, cur) => acc + cur.point, 0);
    return [
      { member: aTeamMember, point: aTeamPoint },
      { member: bTeamMember, point: bTeamPoint },
    ];
  });
}

const match = { combinations, makeMatches, makeMatchesWithPoint };

export default match;
