import { useRecoilValue } from 'recoil';
import { meQuery } from '../../recoil/atoms';

function Nameplate({ user }) {
  const me = useRecoilValue(meQuery);
  const color = user.userId === me.id ? '#dac52e' : '#72b540';
  return (
    <div
      style={{
        display: 'inline-flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
      }}
    >
      <div
        style={{
          padding: '5px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: color,
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          backgroundColor: color,
          color: 'white',
        }}
      >
        {user.point}
      </div>
      <div
        style={{
          maxWidth: '100px',
          padding: '5px',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: color,
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {user.displayName}
      </div>
    </div>
  );
}

export default Nameplate;
