import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditLeague from './EditLeague';
import { meQuery, leaguesQuery, useRefreshLeagues } from '../../recoil/atoms';
import http from '../../lib/http';

function ActionMenu({ league }) {
  const { enqueueSnackbar } = useSnackbar();
  const refreshLeagues = useRefreshLeagues();
  const [openEditLeague, setOpenEditLeague] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    setAnchorEl(null);

    try {
      await http.delete(`/api/leagues/${league.id}`);
      enqueueSnackbar(`리그(${league.displayName})가 삭제되었습니다.`, {
        variant: 'success',
      });
      refreshLeagues();
    } catch (err) {
      enqueueSnackbar(`리그(${league.displayName}) 삭제에 실패하였습니다.`, {
        variant: 'error',
      });
      console.error(err);
    }
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={() => setOpenEditLeague(true)}>이름 변경</MenuItem>
        <MenuItem onClick={handleDelete}>삭제</MenuItem>
      </Menu>
      {openEditLeague && (
        <EditLeague league={league} onClose={() => setOpenEditLeague(false)} />
      )}
    </div>
  );
}

const useStyles = makeStyles(() => ({
  row: {
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
}));

function Leagues() {
  const classes = useStyles();
  const me = useRecoilValue(meQuery);
  const leagues = useRecoilValue(leaguesQuery);

  if (leagues.length === 0) {
    return (
      <Paper
        style={{ marginBottom: '30px', padding: '30px', textAlign: 'center' }}
      >
        참가하고 있는 리그가 없습니다.
        <br />
        새로운 리그를 생성하거나 기존 리그에 초대를 요청하세요.
      </Paper>
    );
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {leagues.map((l) => (
            <TableRow key={l.id} className={classes.row}>
              <TableCell component="th" scope="row">
                <Link component={RouterLink} to={`/leagues/${l.id}`}>
                  {l.displayName}
                </Link>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ width: '10%', padding: '3px', textAlign: 'right' }}
              >
                {l.creatorId === me.id && <ActionMenu league={l} />}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Leagues;
